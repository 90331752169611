import type { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import type { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';

export const updateCartItemsCommand = {
  execute: async (context: VsfContext, { currentCart, products, customQuery = { updateCartItems: 'updateCartItems' } }) => {
    Logger.debug('[Magento]: Update products quantity on cart', {
      products,
      currentCart
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: products
    };

    const { data, errors } = await context.$magento.api.updateCartItems(updateCartParams, customQuery);

    Logger.debug('[Result]:', { data });

    if (errors?.length) {
      return errors;
    }

    return data.updateCartItems.cart as unknown as Cart;
  }
};
