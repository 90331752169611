import { computed } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { defineStore } from 'pinia';

export const useCheckoutStore = defineStore('checkout', () => {
  const headerHeight = sharedRef(0, 'checkout-headerHeight');
  const stepsHeaderHeight = sharedRef(0, 'checkout-stepsHeaderHeight');

  const totalHeaderHeight = computed(() => headerHeight.value);
  const totalStepsHeaderHeight = computed(() => stepsHeaderHeight.value);

  const updateHeaderHeight = (height: number) => {
    headerHeight.value = height;
  };

  const updateStepsHeaderHeight = (height: number) => {
    stepsHeaderHeight.value = height;
  };

  return {
    totalHeaderHeight,
    totalStepsHeaderHeight,
    updateHeaderHeight,
    updateStepsHeaderHeight
  };
});
