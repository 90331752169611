import { computed, readonly, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import type { GraphQLError } from 'graphql';
import merge from 'lodash.merge';

import type { ComposableFunctionArgs } from '~/composables';
// eslint-disable-next-line no-duplicate-imports
import { useStore, useUiNotification } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import { Logger } from '~/helpers/logger';
import { useQuickSearch } from '~/integrations/klevu-search';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import type { Product } from '~/modules/catalog/product/types';
import { addItemsCommand } from '~/modules/core/composables/useCart/commands/addItemsCommand';
import { useCartStore } from '~/modules/core/stores/cart';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { Cart, CartItemInterface, ProductInterface } from '~/modules/GraphQL/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';

import { addItemCommand } from './commands/addItemCommand';
import { applyCouponCommand } from './commands/applyCouponCommand';
import { loadCartCommand } from './commands/loadCartCommand';
import { loadCartStockStatusCommand } from './commands/loadCartStockStatusCommand';
import { loadTotalQtyCommand } from './commands/loadTotalQtyCommand';
import { removeCouponCommand } from './commands/removeCouponCommand';
import { removeItemCommand } from './commands/removeItemCommand';
import { updateCartItemsCommand } from './commands/updateCartItemsCommand';
import { updateEngravingItemCommand } from './commands/updateEngravingItemCommand';
import { updateItemQtyCommand } from './commands/updateItemQtyCommand';
import type { UseCartAddItemEngravingParams, UseCartAddItemsParams, UseCartErrors, UseCartInterface } from './useCart';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
  CART,
  CART_ITEM,
  PRODUCT
> {
  const loading = ref<boolean>(false);
  const itemIsAdding = ref<boolean>(false);
  const { send: sendNotification } = useUiNotification();
  const { setCartInLocalStorageByCode } = useStore();
  const error = ref<UseCartErrors>({
    addItem: null,
    addItems: null,
    removeItem: null,
    updateItemQty: null,
    updateCartItems: null,
    updateEngravingItem: null,
    load: null,
    loadCartStockStatus: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null
  });
  const { app } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const customerStore = useCustomerStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { getAddToCartDetails, getRemoveFromCartDetails } = useGoogleTagManager();
  const quickSearchStore = useQuickSearch();
  const megaMenuStore = useMegaMenuMobileStore();
  const categoryStore = useCategoryStore();
  const productStore = useProductStore();

  const handleCartUpdateError = (errors, errorKey, logContext, title) => {
    error.value[errorKey] = errors;
    errors.forEach((err, i) => {
      Logger.error(logContext, err.message);
      sendNotification({
        icon: 'error',
        id: Symbol(`${errorKey}_error-${i}`),
        message: app.i18n.t('Something went wrong. Please try again!') as string,
        persist: false,
        title,
        type: 'danger',
        area: 'top'
      });
    });
  };

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Merge cart items
   * @param updatedItems
   *
   * @return CART
   */
  const mergeCartItems = (updatedItems: CART | any): CART => {
    Logger.debug('useCart.mergeCartItems', updatedItems);
    const keys = Object.keys(updatedItems).filter((el) => el !== '__typename');

    if (keys?.length) {
      keys.forEach((key) => {
        if (key === 'items') {
          cart.value[key] = merge(cart.value[key], updatedItems[key]);
        } else {
          cart.value[key] = updatedItems[key];
        }
      });
    }
    return cart.value;
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, realCart = false, forceReload = false } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      cartStore.is_cart_loading = true;
      if (!cartStore.initial_loading) {
        cartStore.initial_loading = true;
      }
      const loadedCart = await loadCartCommand.execute(context, { customQuery, realCart, forceReload });
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
      cartStore.is_cart_loading = false;
      cartStore.initial_loading = false;
    }
  };

  const loadCartStockStatus = async ({ cartId }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;

      if (!cartId) {
        throw new Error('Cart ID is required');
      }

      const loadedCart = await loadCartStockStatusCommand.execute(context, { cartId });
      cartStore.$patch((state) => {
        state.cartStockStatus = loadedCart;
      });

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        if (!state.cart) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.cart = {};
        }

        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async (
    { product, quantity, productConfiguration, customQuery },
    engravingCartItems?: UseCartAddItemEngravingParams
  ): Promise<void> => {
    if (itemIsAdding.value) {
      return;
    }
    Logger.debug('useCart.addItem', { product, quantity, engravingCartItems });
    try {
      loading.value = true;
      itemIsAdding.value = true;
      cartStore.is_item_adding = true;
      cartStore.adding_items_count++;
      if (!cartStore.initial_loading) {
        cartStore.initial_loading = true;
      }
      if (!apiState.getCartId() || cart.value?.id !== apiState.getCartId()) {
        Logger.info('[useCart]: Cart id`s are different. Refreshing the cart.', {
          cartId: cart.value?.id,
          apiCartId: apiState.getCartId()
        });

        await load({ realCart: !cart.value?.is_virtual ?? true, forceReload: true });
      }

      setCartInLocalStorageByCode(cart.value);

      const updatedCart = await addItemCommand.execute(
        context,
        {
          currentCart: cart.value,
          product,
          quantity,
          productConfiguration,
          customQuery
        },
        engravingCartItems as UseCartAddItemEngravingParams
      );

      // GTM Event for Add to Cart is not needed for Sample Products
      if (productGetters.getPrice(product).regular > 0) {
        app.$gtm.push(getAddToCartDetails(product as Product, quantity));
      }

      error.value.addItem = null;
      setCart(mergeCartItems(updatedCart as CART));
      sendNotification({
        id: Symbol('product_add'),
        message: product.name,
        type: 'success',
        persist: false,
        area: getNotificationPosition.value,
        title: app.i18n.t('Product added') as string,
        additional_info: { product },
        action_type: 'add_to_cart'
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);

      let errorMsg = app.i18n.t(err.message || err.toString()) as string;

      if (customerStore.isTokenExpired) {
        errorMsg = app.i18n.t('Your session has expired. Log in to continue your shopping.') as string;
      }

      sendNotification({
        id: Symbol('product_add_error'),
        message: errorMsg,
        type: 'danger',
        area: getNotificationPosition.value,
        persist: false,
        title: app.i18n.t('Something went wrong!') as string
      });
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem
        });
      }
      loading.value = false;
      itemIsAdding.value = false;
      cartStore.initial_loading = false;
      cartStore.is_item_adding = false;
      cartStore.adding_items_count--;
    }
  };

  const addItems = async (
    productsItems: UseCartAddItemsParams,
    isAuthenticated?: boolean,
    isInternalBulkProducts = false,
    preventCartMerge = false
  ): Promise<void> => {
    Logger.debug('useCart.addItems', { productsItems });

    if (itemIsAdding.value) {
      return;
    }

    try {
      loading.value = true;
      itemIsAdding.value = true;
      cartStore.is_item_adding = true;
      cartStore.adding_items_count++;
      if (!cartStore.initial_loading) {
        cartStore.initial_loading = true;
      }
      if (!apiState.getCartId() || cart.value?.id !== apiState.getCartId()) {
        Logger.info('[useCart]: Cart id`s are different. Refreshing the cart.', {
          cartId: cart.value?.id,
          apiCartId: apiState.getCartId()
        });

        await load({ realCart: !cart.value?.is_virtual ?? true, forceReload: true });
      }

      setCartInLocalStorageByCode(cart.value);

      const updatedCart = await addItemsCommand.execute(context, productsItems as UseCartAddItemsParams, isAuthenticated, isInternalBulkProducts);

      error.value.addItems = null;

      if (!preventCartMerge) setCart(mergeCartItems(updatedCart as CART));

      sendNotification({
        id: Symbol('add_items_products'),
        message: app.i18n.t('Products successfully added to your bag') as string,
        type: 'success',
        persist: false,
        area: 'top'
      });
    } catch (err) {
      error.value.addItems = err;
      Logger.error('useCart/addItems', err);
      let errorMsg = app.i18n.t('Something went wrong!') as string;

      if (customerStore.isTokenExpired) {
        errorMsg = app.i18n.t('Your session has expired. Log in to continue your shopping.') as string;
      }

      sendNotification({
        id: Symbol('items_products_add_error'),
        message: errorMsg,
        type: 'danger',
        persist: false
      });
    } finally {
      loading.value = false;
      itemIsAdding.value = false;
      cartStore.initial_loading = false;
      cartStore.is_item_adding = false;
      cartStore.adding_items_count--;
    }
  };

  const getNotificationPosition = computed(() => {
    if (
      quickSearchStore.quickSearchOpen ||
      megaMenuStore.isDesktopMenuOpen ||
      categoryStore.isCollectionSidebarOpen ||
      productStore.isEngravingInfoOpen
    ) {
      return 'top';
    }
    return 'default';
  });

  const removeItem = async ({ product, customQuery }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery
      });

      // GTM Event for Remove from Cart is not needed for Sample Products
      if (productGetters.getPrice(product.product).regular > 0) {
        app.$gtm.push(getRemoveFromCartDetails(product.product as Product, product.quantity));
      }

      if (!Array.isArray(updatedCart)) {
        error.value.removeItem = null;
        setCart(removeItemCommand.mergeAfterRemoving(updatedCart, cart.value, app));
      } else {
        handleCartUpdateError(updatedCart, 'removeItem', 'useCart/removeItem', 'Remove error');
      }
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const finalizeCartUpdate = (updatedCart: Cart | readonly GraphQLError[]): boolean => {
    if (Array.isArray(updatedCart)) {
      handleCartUpdateError(updatedCart, 'finalizeCartUpdate', 'useCart/finalizeCartUpdate', 'Update error');

      return false;
    }

    setCart(mergeCartItems(updatedCart as Cart));
    return true;
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery
        });

        const qtyDiff = Math.abs(quantity - product.quantity);
        if (product.quantity < quantity) {
          app.$gtm.push(getAddToCartDetails(product.product as Product, qtyDiff));
        } else {
          app.$gtm.push(getRemoveFromCartDetails(product.product as Product, qtyDiff));
        }

        if (finalizeCartUpdate(updatedCart)) {
          error.value.updateItemQty = null;
        }
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const updateCartItems = async ({ products, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateCartItems', {
      products
    });

    if (products) {
      try {
        loading.value = true;
        const updatedCart = await updateCartItemsCommand.execute(context, {
          currentCart: cart.value,
          products,
          customQuery
        });

        if (finalizeCartUpdate(updatedCart)) {
          error.value.updateItemQty = null;
        }
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateCartItems', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const updateEngravingItem = async ({
    product,
    quantity,
    customizable_options,
    customQuery = { updateCartItems: 'updateCartItems' }
  }): Promise<void> => {
    Logger.debug('useCart.updateEngravingItem', {
      product,
      quantity,
      customizable_options
    });

    try {
      loading.value = true;
      const updatedCart = await updateEngravingItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        customizable_options,
        customQuery
      });

      if (finalizeCartUpdate(updatedCart)) {
        error.value.updateItemQty = null;
      }
    } catch (err) {
      error.value.updateEngravingItem = err;
      Logger.error('useCart/updateEngravingItem', err);
    } finally {
      loading.value = false;
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant?.uid;
    }
    const isSalable = product?.is_salable;
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null ? true : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft && isSalable;
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    addItems,
    load,
    loadCartStockStatus,
    removeItem,
    clear,
    updateItemQty,
    updateCartItems,
    updateEngravingItem,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
    mergeCartItems
  };
}

export default useCart;
export * from './useCart';
